<template>
  <div style="color: #000" class="box my_warp">
    <h1 style="text-align: center; font-size: 17px;padding:15px 0">{{COMPANY_NAME_All}}直播隐私政策</h1>
    &emsp;&emsp;{{COMPANY_NAME_All}}直播由{{COMPANY_NAME_All}}及其关联公司（以下简称“我们”或“公司”）提供产品运营和服务。本《隐私政策》（以下简称“本政策”）阐述了我们将如何处理您的个人信息，并申明了公司对保护用户隐私的承诺。我们将依据《中华人民共和国个人信息保护法》、《中华人民共和国数据安全法》、《中华人民共和国网络安全法》以及其他相关法律法规和技术规范收集和使用您的个人信息，尽力保护您的个人信息安全可控。
    本政策适用于{{COMPANY_NAME_All}}直播产品和服务。未满14周岁的用户，同时适用本政策以及《{{COMPANY_NAME_All}}直播儿童个人信息保护指引》。<br />
    &emsp;&emsp;如果为了向您提供服务而需要将您的信息共享至第三方，我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。需要特别说明的是，本政策不适用其他独立第三方向您提供服务。例如我们平台上的第三方依托本平台向您提供服务时，我们会向您提示该服务由独立第三方提供，您向第三方提供个人信息不适用于本隐私权政策。
    在使用{{COMPANY_NAME_All}}直播前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用公司的各项产品或服务，即表示您已充分理解并同意本隐私政策。
    您可以通过本隐私政策所列的途径访问、更正或删除您的个人信息，也可以进行隐私设置或与我们取得联系。<br />
    下文将帮助您详细了解我们如何收集、使用和保护您的个人信息；帮助您了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关您个人信息权益的重要条款我们已用加粗形式提示，请特别关注。<br />
    &emsp;&emsp;一、我们如何收集和使用个人信息<br />
    &emsp;&emsp;二、我们如何使用Cookie等同类技术<br />
    &emsp;&emsp;三、我们如何共享、转让、公开披露个人信息 <br />
    &emsp;&emsp;四、我们如何存储个人信息<br />
    &emsp;&emsp;五、我们如何保护个人信息的安全 <br />
    &emsp;&emsp;六、管理您的个人信息 <br />
    &emsp;&emsp;七、未成年人条款<br />
    &emsp;&emsp;八、隐私政策更新 <br />
    &emsp;&emsp;九、争议解决 <br />
    &emsp;&emsp;十、联系我们<br />
    一、我们如何收集和使用您的个人信息<br />
    &emsp;&emsp;在您使用我们的服务过程中，我们会按照如下方式收集您在使用我们服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以及保证您的账户安全。
    请注意，如果您仅需用{{COMPANY_NAME_All}}直播的基本功能服务（即进行实时视频、音频、图文等形式的信息浏览），您无需授权并向我们提供任何个人信息。但我们会出于保障产品和服务安全的目的收集您使用我们的产品或服务过程中所产生的网络日志和行为日志信息。单独的设备信息、日志信息是无法识别特定自然人身份的信息，在您未提供其他能够识别您个人身份的个人信息的情况下，此类信息无法与特定自然人进行关联。如果我们将这类非个人信息与您提供的其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
    您需授权我们收集和使用您的个人信息的其他功能/场景包括：<br />
    （一）注册、登录<br />
    &emsp;&emsp;（1）当您注册、登录{{COMPANY_NAME_All}}直播账号时，我们会收集您的昵称、头像、手机号码、电子邮箱，并为平台注册用户生成{{COMPANY_NAME_All}}直播号，收集这些信息是为了帮助您完成{{COMPANY_NAME_All}}直播账号注册，保护您账号的安全。手机号码属于敏感信息，收集该信息是为了以此作为更为高效、可靠的登录凭证。通过注册验证且您阅读并同意本政策及用户协议后，将完成账号注册及登录。如果您仅需使用浏览、搜索服务，您不需要注册账号及提供上述信息。<br />
    &emsp;&emsp;（2）您也可以使用第三方账号登录我们平台。如您使用第三方平台账号登录的，我们将根据您的授权获取您该第三方账号下的相关信息（包括用户ID、昵称、头像及账号验证信息）。我们从第三方获取您上述信息的目的是为了帮助您快速生成用户登录身份，以便您更快捷的完成注册及登录。您可以随时在“我的”-“设置”-“账号与安全”-“第三方绑定”中，选择绑定或取消绑定上述第三方账号信息。<br />
    &emsp;&emsp;（3）在您登录{{COMPANY_NAME_All}}直播账号后，如果您愿意额外补充头像、昵称、性别、生日、地区、签名、草稿箱、发布的视频等个人资料，将有助于我们为您提供个性化的服务。如果您不提供前述信息，不会影响您享受{{COMPANY_NAME_All}}直播的基本功能。如果您仅需要使用浏览功能，您也可以选择在不登录且不提供上述信息的情况下进行。<br />
    （二）实名认证<br />
    在您使用身份认证的功能或相关服务所需时，根据国家相关法律法规，您可能需要提供您的真实身份信息以完成实名认证，如果您不提供相关信息，可能无法使用具体功能或服务。主要包括以下几类：<br />
    &emsp;&emsp;（1）注册{{COMPANY_NAME_All}}直播账号时，需要提供您的手机号码完成实名认证，完成认证后您方可发送弹幕或上传内容。<br />
    &emsp;&emsp;（2）当您申请注册为网络直播服务发布者（主播）时，{{COMPANY_NAME_All}}直播将根据相关法律规定收集您的真实姓名、身份证件信息进行实名身份认证，并通过您的面部识别信息进行身份一致性核验。如您选择人工认证方式完成实名认证，我们可能收集您的真实姓名、身份证件信息（包括但不限于身份证、相应证件的正反面图片和您手持相应身份证件的照片）。通过实名认证后，您方可在平台上从事直播发布活动。如果您有主播佣金提现需求，您还需要提供与您身份信息一致的银行卡信息。为了准确验证您的身份，我们将对上述信息进行比对核验。您的真实姓名、身份证件信息、面部识别信息、手持身份证件信息和银行卡号属于个人敏感信息，我们收集该类信息是基于实名认证要求，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务（直播开播、提现等功能），但不影响您正常使用产品的浏览和观看功能。<br />
    &emsp;&emsp;（3）当您参与某些提现活动时，根据相关监管政策要求，我们需要对提现用户进行实名认证，可能需要获取您的身份证号、姓名等信息，具体以活动页面公示内容为准。<br />
    （三）安全保障<br />
    &emsp;&emsp;为保障网络安全、预防网络犯罪、识别您的账号异常以及反欺诈目的，我们会收集您以下信息：<br />
    &emsp;&emsp;（1）网络访问日志信息和用户日志信息。这些日志信息包括：账号、登录日志、操作时间、操作记录、网络源地址和目标地址、网络源端口、客户端硬件特征、通讯群组名称、昵称、简介、备注、标识、用户信息发布、转发、评论记录；<br />
    &emsp;&emsp;（2）您的设备信息。包括：设备型号、操作系统、唯一设备标识符（IMEI/AndroidID/IDFA/OpenUDID/GUID/SIM
    卡IMSI信息）、SIM集成电路卡识别码ICCID、地理位置信息、应用列表、网络设备硬件地址（MAC）、登陆IP地址、软件版本号、接入网络的方式、类型和状态、网络质量数据、WIFI扫描列表、WIFI名称（SSID）、WIFI
    MAC地址（BSSID）、设备加速器（如重力感应设备）、设备传感器信息。<br />
    &emsp;&emsp;上述信息将被用于综合识别、检测违规行为和内容、实名核验，并采取必要的记录、审计、分析和处置措施。<br />
    （四）展示或推送服务 当您使用{{COMPANY_NAME_All}}直播时，我们会收集您的如下信息：<br />
    &emsp;&emsp;（1）关注、搜索等您的操作、使用行为信息、性别、年龄、地理位置信息；<br/>
    &emsp;&emsp;（2）您的设备信息（如IDFA、OAID、安装应用列表信息）；<br />
    &emsp;&emsp;（3）反馈、发布、点赞、评论、打赏等您主动提供的互动信息。<br />
    &emsp;&emsp;当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们会通过IP地址、GPS以及能够提供位置信息的WLAN或基站途径获取您的地理位置信息。该信息属于敏感信息，拒绝提供该信息会使您无法使用与位置相关的功能（例如为重庆地区用户提供重庆地区直播内容、推荐您附近主播的直播内容），但不影响您正常使用网站的其他功能。
    除取得您授权或法律法规另有规定外，我们不会将上述信息与您在应用程序中提供的任何个人身份信息相结合，以确保不识别到您个人。我们可能将上述信息与来自我们服务所收集的其他非身份信息结合，通过程序算法进行特征与偏好分析、基于特征标签进行间接人群画像，用以向您推荐、展示或推送更合适您的特定功能或服务。<br />
    （五）其他附加功能<br />
    &emsp;&emsp;1、支付业务功能。当您使用{{COMPANY_NAME_All}}直播产品的消费功能时，我们会收集您的充值记录、消费记录信息，以便您查询自己的交易记录，同时尽最大程度保护您的财产、虚拟财产安全。上述信息属于敏感信息，但收集上述信息为实现相关功能所必须，否则将无法完成交易。<br />
    &emsp;&emsp;2、基于相机/摄像头的业务功能。您可在开启相机/摄像头权限后使用{{COMPANY_NAME_All}}直播的扫描二维码、视频直播和拍摄功能，以及在特定场景下经您授权的人脸识别等功能。当您使用该业务功能进行人脸识别时我们会收集您的面部特征，且严格在经您授权同意的范围内使用，未来如我们拟使用您的面部信息为您提供其他产品及/或服务功能的，我们会再次与您确认。当您通过iOS系统使用{{COMPANY_NAME_All}}直播的开播组件时，我们可能会使用ARKit和TrueDepth
    API中的人脸映像深度相关信息的计算结果，用于美颜、特效滤镜、贴纸、虚拟形象等功能以达到更好的人脸处理效果；在此期间，我们既不会将该等运作结果用于其他目的，也不会上传或存储至服务器。<br />
    &emsp;&emsp;3、基于相册（图片库/视频库）的图片/视频访问及上传的附加服务。您可以在开启权限后使用相关功能上传您的照片/图片/视频，以实现更换头像、发布动态、上传下载等功能。<br />
    &emsp;&emsp;4、基于麦克风语音技术的附加服务。您使用语音转文字功能发送弹幕评论、开直播、使用语音交友功能时，我们会请求您授权麦克风权限并收集您的语音内容。<br />
    &emsp;&emsp;5、调研与推广营销。当您选择参加我们及我们的关联方或第三方举办的有关调查问卷、抽奖中奖、线上或线下推广营销等运营活动时，可能需要您在相关表单中填写姓名、通信地址、联系方式等个人信息。如拒绝提供相关信息，可能会影响您参加相关活动，但不会影响您使用其他功能。只有经过您的同意，我们及第三方才会收集和处理相关信息，以保障为您提供相应的产品服务。此外，基于平台运营管理需求，我们可能会使用部分平台主播联系方式与其沟通业务运营事宜。<br />
    &emsp;&emsp;6、消息通知。你知悉并同意，我们在运营中可能会通过你在使用产品及/或服务的过程中所提供的联系方式（手机号码），向你同时发送一种或多种类型的通知，例如消息告知、身份验证、安全验证、用户调研。但请你放心，如你不愿接受这些信息，你可以通过{{COMPANY_NAME_All}}直播网站提供的退订方式进行退订，也可以直接与我们联系进行退订。<br />
    &emsp;&emsp;7、分享互动。在你分享或接收被分享的信息、参加活动等情形下，{{COMPANY_NAME_All}}直播客户端需要在本地访问你的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。我们仅在本地识别出剪切板内容属于{{COMPANY_NAME_All}}直播跳转、分享、活动联动等指令时才会将其上传我们的服务器。除此之外，{{COMPANY_NAME_All}}直播客户端不会上传你剪切板的其他信息至我们的服务器。另外，我们可能需要获取存储/相册权限以便于你分享或接收被分享的视频和图片。
    我们在提供服务的过程中，可能需要向您申请部分设备权限。这些权限均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您可以通过“设置”-“隐私设置”-“系统权限设置”，撤回您对相关权限的授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。<br />
    （六）客服和申诉<br />
    &emsp;&emsp;当您向我们的客服或通过产品交互页面发起有关账号安全和未成年人消费相关的申诉或进行咨询时，为了方便与您联系、帮助您解决问题，确保您的账号安全，我们可能需要您提供部分信息或资料，您可以自行决定是否向我们提供，这些资料可能包括您的账号实名信息和账号使用信息，收集这些信息是为了确保您的账号不在被盗或未被授权的情况下发起申诉或咨询，保障您的账号和财产安全。<br />
    （七）授权同意的例外<br />
    根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用、共享、转让、公开披露您的相关个人信息无需征求您的授权同意：<br />
    &emsp;&emsp;1、与国家安全、国防安全等国家利益直接相关的；<br />
    &emsp;&emsp;2、与公共安全、公共卫生、公众知情等重大公共利益直接相关的；<br />
    &emsp;&emsp;3、与犯罪侦查、起诉、审判和判决执行等直接相关的；<br />
    &emsp;&emsp;4、出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；<br />
    &emsp;&emsp;5、所收集的个人信息是您自行向社会公众公开的；<br />
    &emsp;&emsp;6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />
    &emsp;&emsp;7、根据您要求签订和履行合同、提供服务、实现售后和交易服务所必需的；<br />
    &emsp;&emsp;8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br />
    &emsp;&emsp;9、有权机关的要求、法律法规规定的其他情形。<br />
    请理解，由于{{COMPANY_NAME_All}}直播服务的更新迭代，如果某一功能或服务未及时在本隐私政策中声明收集了你的信息，我们仍然会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的授权同意。
    请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。<br />
    二、我们如何使用Cookie等同类技术 Cookie<br />
    &emsp;&emsp;和设备信息标识等同类技术是互联网中普遍使用的技术。当您使用{{COMPANY_NAME_All}}直播及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集、标识您访问、使用本产品时的信息。我们承诺，不会将Cookie
    用于本隐私政策所述目的之外的任何其他用途。我们使用 Cookie
    和同类技术主要为了实现以下功能或服务： <br />
    &emsp;&emsp;1、保障产品与服务的安全、高效运转,我们可能会设置认证与保障安全性的
    Cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。<br />
    &emsp;&emsp;2、帮助您获得更轻松的访问体验,使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程。<br />
    &emsp;&emsp;3、向您推荐您可能感兴趣的直播内容,我们可能会利用此类技术了解您使用{{COMPANY_NAME_All}}直播服务的偏好和使用习惯，进行数据分析，向您推荐你可能感兴趣的直播内容。<br />
    &emsp;&emsp;4、Cookie的清除,大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作；您也可以管理和清除由{{COMPANY_NAME_All}}直播网站保存的所有cookie。如您进行清除，您可能无法使用由我们提供的、依赖于Cookie的个性化推荐服务及其他相应功能。<br />
    三、我们如何共享、转让、公开披露您的信息 <br />
    （一）共享<br />
    我们将对您的信息承担保密义务，除以下情形外，我们不会对外共享或提供您的个人信息：<br />
    &emsp;&emsp;1、事先已获得您的明确授权或同意；<br />
    &emsp;&emsp;2、根据适用的法律法规、法律程序、行政司法机关的要求或司法争议解决而需共享您的个人信息；<br />
    &emsp;&emsp;3、在法律要求或允许的范围内，为了保护{{COMPANY_NAME_All}}直播及其用户或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方。<br />
    &emsp;&emsp;4、与关联公司共享：为便于我们基于{{COMPANY_NAME_All}}直播或关联公司的账号向您提供一致化的产品和服务，在{{COMPANY_NAME_All}}直播或{{COMPANY_NAME_All}}直播关联公司的多个产品中用于方便您使用关联账号注册登录，向您推荐您可能感兴趣的内容和信息，保护{{COMPANY_NAME_All}}直播或{{COMPANY_NAME_All}}直播关联公司用户的账号及财产安全，您的账号信息、实名认证信息、设备和日志信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意；<br />
    &emsp;&emsp;5、与授权合作伙伴共享：为了向您提供更完善、优质的产品和服务，我们可能委托授权合作伙伴或使用第三方SDK相关技术为您提供服务或代表我们履行职能。我们仅会出于本隐私政策声明的合法、正当、必要、特定、明确的目的共享您的信息，合作伙伴只能接触到其履行职责所需的信息，且不得将信息用于其他任何目的。<br />
    &emsp;&emsp;目前，我们的授权合作伙伴包括以下类型：<br />
    &emsp;&emsp;（1）基础服务提供商：包括提供实名认证、支付、一键登录、消息推送、地理位置定位、反欺诈的合作方，与这些服务提供商的信息共享大多通过SDK方式进行。<br />
    &emsp;&emsp;（2）委托我们进行信息推广和广告投放的合作伙伴：您授权我们有权与委托我们进行信息推广和广告投放的合作伙伴共享我们使用您的相关信息形成的无法识别您的个人身份的间接用户画像、设备信息、去标识化或匿名化处理后的分析/统计类信息，以帮助其在不识别您个人身份的前提下进行广告或决策建议、提高广告有效触达率、衡量广告和相关服务的有效性。<br />
    &emsp;&emsp;（3）提供数据服务（包括网络广告监测、数据统计、数据分析）的合作伙伴：为维护/改进我们的产品/服务、为您提供更好的内容，我们可能会与提供该服务的指定合作伙伴共享您的相关信息（包括：设备信息、广告展示和点击记录、终端型号、地理位置），除非得到您的同意，我们不会与其共享您的个人身份信息。<br />
    &emsp;&emsp;（4）内容安全监测服务提供商：我们可能与特定内容安全监测服务提供商合作进行违规音视频标注和分析，可能会与这些服务提供商共享直播流信息。<br />
    &emsp;&emsp;（5）提供开播或直播推流服务的合作伙伴：当我们与提供开播或直播推流服务的第三方平台进行合作时，我们可能共享用户昵称、弹幕、评论、排行榜、打赏信息等直播间已公开的用户数据。<br />
    &emsp;&emsp;6、为保障{{COMPANY_NAME_All}}直播网站相关功能的实现与应用安全稳定运行，我们产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，我们也会努力审查该第三方的业务准入资质并努力要求该等服务商的合法合规性与安全性。如您发现该等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。
    对共享信息的合作方，我们会与其订立专门的数据保护协议或数据安全条款，约定严格的数据保护措施，在确保安全的前提下共享信息，令其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br />
    （二）转让<br />
    我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br />
    &emsp;&emsp;1、您自行提出要求的;<br />
    &emsp;&emsp;2、事先已征得您的明确授权同意;<br />
    &emsp;&emsp;3、在{{COMPANY_NAME_All}}直播公司发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。<br />
    （三）公开披露 我们仅会在以下情况下，公开披露您的个人信息：<br />
    &emsp;&emsp;1、除了因需要对违规账号、欺诈行为等违反{{COMPANY_NAME_All}}直播协议及平台规则进行处罚公告、公布中奖/获胜者等名单时脱敏展示相关信息等必要事宜而进行的必要披露外，我们不会对您的个人信息进行公开披露。您同意我们在上述必要场景披露您的相关信息，同时我们将审慎评估公开披露的合法性、正当性、合理性，采取严格的个人信息安全保护措施对其进行保护。<br />
    &emsp;&emsp;2、如具备合理事由确需公开披露的，我们会在公开披露前向您告知公开披露的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容），并在征得您的授权同意后再公开披露，但法律法规另有规定的或本政策另有约定的除外。<br />
    四、我们如何存储个人信息<br />
    &emsp;&emsp;我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，不会对您的个人信息跨境传输。我们仅在为提供服务目的所必须的期间内保留您的个人信息，在您未撤回、删除或未注销账号前，我们会保留相关信息。超出必要期限后，我们将对个人信息进行删除或匿名化处理，但在下列情形下，我们需要遵守相关法律法规要求留存您的个人信息。<br />
    &emsp;&emsp;1、《中华人民共和国网络安全法》第二十一条第三款要求监测、记录网络运行状态、网络安全事件的技术措施的网络日志留存不得少于六个月；<br />
    &emsp;&emsp;2、《互联网直播服务管理规定》要求记录互联网直播服务使用者发布内容和日志信息，保存六十日；<br />
    &emsp;&emsp;3、为遵守法院判决、裁定或其他法律程序的规定；<br />
    &emsp;&emsp;4、为执行平台协议或本政策、维护社会公共利益，保护公司及公司关联公司、其他用户或雇员的人身安全或其他合法权益所合理必须的用途。<br />
    五、我们如何保护个人信息的安全<br />
    &emsp;&emsp;为保障您的信息安全，我们努力采取各种符合业界标准、合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于部署主机入侵检测系统、重要敏感信息加密存储、数据中心的访问控制、日志记录安全审计。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄漏、毁损或丢失。
    您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即联络我们客服，以便我们采取相应措施。<br />
    六、管理您的个人信息<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播非常重视用户的个人信息权益，并尽全力保障您对自己个人信息享有的各项法定权利，以便您拥有充分的能力保障您的信息安全。您的权利包括：<br />
    （一）查询、更正和删除个人信息<br />
    &emsp;&emsp;你可以通过以下方式查询、更正或删除自己的信息：<br />
    &emsp;&emsp;1、登录{{COMPANY_NAME_All}}直播网站，进入“我的”→“我的昵称”→“编辑资料”，对头像、昵称、性别、生日、地区、签名等个人资料进行查询、更正或删除；<br />
    &emsp;&emsp;2、登录{{COMPANY_NAME_All}}直播网站，进入“我的”→“设置”→“账号与安全”，查询{{COMPANY_NAME_All}}直播号、手机号、绑定的第三方账号、密码、关联账号等信息，并修改或解除绑定其中的某些信息。<br />
    &emsp;&emsp;3、登录{{COMPANY_NAME_All}}直播网站，进入“我的”→“我的订阅”，查询、修改和取消关注订阅主播。<br />
    &emsp;&emsp;4、其他信息：如您在行使上述权利的过程中遇到困难，或其他目前可能无法向您提供自行查询/更正/删除服务的，您可以按照本隐私政策所提供的方式联系我们。<br />
    当您发现我们违反法律、行政法规的规定或者双方的约定存储处理您的个人信息，您可以要求我们删除。如您发现我们收集、存储的您的个人信息有错误的，您也可以要求我们更正或补充。<br />
    （二）控制信息公开的范围<br />
    您可以通过登录{{COMPANY_NAME_All}}直播网站，进入“我的”→“设置”→“隐私设置”→“个人主页展示设置”，选择是否公开展示相关等信息。<br />
    （三）撤回授权<br />
    &emsp;&emsp;如前所述，{{COMPANY_NAME_All}}直播提供的部分功能需要获得您使用设备的相关权限（包括：存储、位置、相机、麦克风等系统权限，详见第一部分）。您可以在授权后随时撤回（或停止）对该权限的授权。当您更新网站版本后，未经您的明确同意，我们不会更改您之前设置的权限状态。例如您可以通过“设置”→“隐私设置”→“系统权限设置”，撤回您对相关权限的授权。<br />
    &emsp;&emsp;您也可以通过注销账号的方式，永久撤回我们继续处理您个人信息的全部授权。<br />
    &emsp;&emsp;如您在行使上述权利的过程中遇到困难，或其他目前可能无法向您提供自行撤回授权的，您可以按照本隐私政策所提供的方式联系我们。请您知悉，当您撤回授权后，我们无法继续为您提供撤回授权所对应的特定功能和/或服务。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。<br />
    （四）个人信息副本获取与转移<br />
    &emsp;&emsp;如您需要获取您的个人信息副本，您可以发送邮件到xzb@nineton.cn与我们联系并说明您的要求，我们将在收到您联系后的15个工作日内完成核验用户身份和处理。<br />
    &emsp;&emsp;如您需要转移我们处理的个人信息，我们将根据相关法律法规的要求，协助您进行转移。<br />
    （五）注销账号 您可以通过以下方式注销自己的账号：<br />
    &emsp;&emsp;1、登录{{COMPANY_NAME_All}}直播网站，进入“我的”→“设置”→“账号与安全”→“注销账号”；<br />
    &emsp;&emsp;2、通过xzb@nineton.cn客服的帮忙完成账号注销。<br />
    &emsp;&emsp;3、按照本政策文末所列明的公开联系方式，联系我们协助您注销账号。<br />
    &emsp;&emsp;在您主动注销账户之后，我们将在十五个工作日内进行处理。账号注销后，我们将停止为您提供与该{{COMPANY_NAME_All}}直播账号相关联的所有产品和服务，根据适用法律的要求删除您的个人信息或进行匿名化处理，但法律法规另有规定的除外。
    请注意，账号注销后您已发布的所有内容（包括提供的音视频、图文等信息）将转为匿名发布，如需删除内容，请在申请注销前自行处理。<br />
    （六）访问和要求解释隐私政策<br />
    &emsp;&emsp;我们将在您首次开启、注册和登录{{COMPANY_NAME_All}}直播网站时，向您提示本隐私政策并由您自主作出选择同意的决定。另外，您可以通过“设置”→“隐私设置”→“隐私协议”页面随时查看本隐私政策的全部内容。<br />
    &emsp;&emsp;如您对我们的个人信息处理规则有任何疑问，您可以通过本隐私政策披露的联系方式要求我们解释说明。<br />
    （七）有权获得停止运营的告知<br />
    &emsp;&emsp;如果{{COMPANY_NAME_All}}直播平台终止服务或运营，我们将及时停止收集您个人信息的活动，并至少提前三十日将停止运营的通知以公告或逐一送达等形式通知您，并在终止服务或运营后对所持有的您的个人信息进行删除或匿名化处理，法律法规另有规定的除外。<br />
    （八）其他权利<br />
    &emsp;&emsp;如{{COMPANY_NAME_All}}直播用户（仅限自然人）不幸逝世，其近亲属为了自身的合法、正当利益，可以对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但去世用户生前另有安排的除外。<br />
    &emsp;&emsp;关于上述权利，您可以发送邮件至xzb@nineton.cn与我们联系并说明您的要求，我们将在收到您联系后的15个工作日内进行身份核验和处理。<br />
    七、未成年人条款<br />
    &emsp;&emsp;如您为未成年人，我们要求您请您的父母或监护人在仔细阅读本隐私政策，并在征得您的父母或监护人的同意之后，在他们的指导下使用我们的服务。<br />
    &emsp;&emsp;对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如果您认为我们可能不当地持有关于未成年人的信息，请联系我们。<br />
    &emsp;&emsp;如果您是未满14周岁的儿童或儿童的监护人，当您在完成或帮助儿童完成产品或服务的注册、使用前，应当仔细阅读本政策，具体产品的隐私政策（如有）和《{{COMPANY_NAME_All}}直播儿童个人信息保护指引》，以决定是否同意本政策、具体产品的隐私政策，以便儿童能使用我们提供的产品或服务。<br />
    &emsp;&emsp;为保护儿童及未成年人的个人信息，我们设立了专门的儿童个人信息保护专职机构，如您对您所监护的儿童的个人信息保护有相关疑问或权利请求时，请通过《{{COMPANY_NAME_All}}直播儿童个人信息保护指引》中披露的联系方式与我们联系。我们会在合理的时间内处理并回复您。<br />
    八、隐私政策更新<br />
    &emsp;&emsp;1、为给您提供更好的服务以及随着{{COMPANY_NAME_All}}直播业务的发展，本隐私政策也会随之更新，该等更新构成本隐私政策一部分。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们将在更新后的本隐私政策生效前，通过显著位置提示或向您发送推送消息或以其他方式通知您，也请您访问{{COMPANY_NAME_All}}直播以便及时了解最新的隐私政策。如该等更新造成您在本隐私政策下权利的实质减少或重大变更，我们会再次征求您的明示同意。
    您需理解，只有在您确认并同意变更后的《{{COMPANY_NAME_All}}直播隐私政策》，我们才会依据变更后的隐私政策收集、使用、处理和存储您的个人信息；您有权拒绝同意变更后的隐私政策，但请您知悉，一旦您拒绝同意变更后的隐私政策，可能导致您不能或不能继续完整使用{{COMPANY_NAME_All}}直播的相关服务和功能，或者无法达到我们拟达到的服务效果。<br />
    &emsp;&emsp;2、本隐私政策所指的重大变更包括但不限于：<br />
    &emsp;&emsp;（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br />
    &emsp;&emsp;（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br />
    &emsp;&emsp;（3）个人信息共享、转让或公开披露的主要对象发生变化；<br />
    &emsp;&emsp;（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；<br />
    &emsp;&emsp;（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br />
    &emsp;&emsp;（6）个人信息安全影响评估报告表明存在高风险时。 <br />
    九、争议解决<br />
    &emsp;&emsp;本隐私政策适用中华人民共和国大陆地区法律。任何因本隐私政策以及我们处理您个人信息事宜引发的争议，首先可友好协商解决；协商不成的，您可向可向被告方有管辖权的法院提起诉讼来寻求解决方案。<br />
    &emsp;&emsp;本隐私政策的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。<br />
    十、如何联系我们 若您对我们的隐私政策有任何投诉或疑问，可随时联系我们。<br />
    &emsp;&emsp;公司名称：{{COMPANY_NAME_All}} 联系邮箱：xzb@nineton.cn<br />
    &emsp;&emsp;联系人：{{COMPANY_NAME_All}}直播法务<br />
    &emsp;&emsp;我们将尽快审核所涉问题，并在验证您的用户身份后十五日内回复。<br />
  </div>
</template>

<script>
export default {
  data(){
    return{
      COMPANY_NAME_ABB:process.env.VUE_APP_COMPANY_NAME_ABB,
      COMPANY_NAME_All:process.env.VUE_APP_COMPANY_NAME_All,
    }
  }
};
</script>

<style lang="less" scoped>
.box{
  font-size: 12px;
}
</style>